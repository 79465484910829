// src/SuccessPage.js
import React from 'react';

function SuccessPage() {
  return (
    <div className="container mt-5">
      <h1>Success</h1>
      <p>Your registration has been completed successfully.</p>
    </div>
  );
}

export default SuccessPage;
