// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import SuccessPage from './pages/SuccessPage';

function App() {
  return (
    <Routes>
      <Route path="register/:id" element={<RegisterPage />} />
      <Route path="success" element={<SuccessPage />} />
      {/* Add other routes here if needed */}
    </Routes>
  );
}

export default App;
