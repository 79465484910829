// src/RegisterPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const appId = process.env.REACT_APP_META_APP_ID;
const configId = process.env.REACT_APP_META_CONFIG_ID;

function RegisterPage() {
  const { id } = useParams(); // Extract the id from the URL
  const navigate = useNavigate(); // For redirection
  const [botName, setBotName] = useState('');
  const [authCode, setAuthCode] = useState(null);
  const [metaAccount, setMetaAccount] = useState(null);
  const [sessionInfoResponse, setSessionInfoResponse] = useState('');
  const [sdkResponse, setSdkResponse] = useState('');

  useEffect(() => {
    // Fetch the document from Firestore
    const fetchBotData = async () => {
      try {
        const docRef = doc(db, 'pendingBots', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setBotName(data.name);
        } else {
          console.log('No such document!');
          setBotName(null); // Indicate that the document doesn't exist
        }
      } catch (error) {
        console.error('Error fetching bot data:', error);
        setBotName(null); // Indicate an error occurred
      }
    };

    fetchBotData();
  }, [id]);

  // Function to handle Facebook login callback
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      setAuthCode(response.authResponse.code)

      /*
      // Use an IIFE to handle async operations
      (async () => {
      })();
*/

    } else {
      // Handle login failure
      console.error('Login failed:', response);
    }
    setSdkResponse(JSON.stringify(response, null, 2));
  };

  // Function to launch WhatsApp Signup
  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: configId, // Replace with your configuration ID
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      },
    });
  };

  // Effect hook to load Facebook SDK and set up event listeners
  useEffect(() => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId, // Replace with your App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Load the SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.defer = true;
      js.crossOrigin = 'anonymous';
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // Event listener for messages from Facebook
    const handleMessage = (event) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // Handle different events
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            setMetaAccount({ phone_number_id, waba_id })
            console.log(
              'Phone number ID ',
              phone_number_id,
              ' WhatsApp business account ID ',
              waba_id
            );
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn('Cancel at ', current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error('Error ', error_message);
          }
        }
        setSessionInfoResponse(JSON.stringify(data, null, 2));
      } catch {
        console.log('Non-JSON Responses', event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    async function updateBotData () {
      if (authCode && metaAccount) {

        try {

          // Update pending bot with new info
          const docRef = doc(db, 'pendingBots', id);
          await updateDoc(docRef, {
            metaParams: {
              authCode: authCode,
              phoneNumberId: metaAccount.phone_number_id,
              wabaId: metaAccount.waba_id
            }
          });

          // Redirect to /success
          navigate('/success');
        } catch (error) {
          console.error('Error writing to Firestore:', error);
          // Handle the error as needed
        }

      }
    }
    updateBotData()
  }, [authCode, metaAccount, navigate, id]);

  return (
    <div className="container mt-5">
      <div id="fb-root"></div>
      {botName === '' && <p>Loading bot information...</p>}
      {botName && (
        <>
          <h3>Welcome, {botName}</h3>
          <button onClick={launchWhatsAppSignup} className="btn btn-primary">
            Login with Facebook
          </button>
        </>
      )}
      {botName === null && <p>Bot not found or an error occurred.</p>}
      <p>Session info response:</p>
      <pre>{sessionInfoResponse}</pre>
      <br />
      <p>SDK response:</p>
      <pre>{sdkResponse}</pre>
    </div>
  );
}

export default RegisterPage;
